<template>
    <div class='recipes'>
        <div class="basicInfo funMedicine">
            <div class="heads">
                <div class="basicInfo-img">
                    <div class="name">
                        姓名：{{ name }}
                    </div>
                    <div class="Opening">
                        开方时间：{{ openTime }}
                    </div>
                </div>
            </div>
            <div class="formula">
                <div class="formula-img">
                    <img class="img"
                        src="https://image.giantgocloud.com/www//ImageMapping/image/20230719/B8E93105940941359A1D3513790758D0.png"
                        alt="">
                </div>
                <div class="formula-text">
                    食养汤方
                </div>
            </div>
            <div class="foodProductList">
                <div class="foodProductList-item" :style="{ backgroundImage: 'url(' + item.FoodPicture + ')' }"
                    v-for="(item, index) in foodProductList" :key="index">
                    <div class="round">
                        0{{ index + 1 }}
                    </div>
                    <div class="btn-text">
                        {{ item.FoodName }}
                    </div>
                </div>
            </div>
            <div class="formula">
                <div class="formula-img">
                    <img class="img"
                        src="https://image.giantgocloud.com/www//ImageMapping/image/20230719/B1DF4A5518854482A50BC80BCA95892F.png"
                        alt="">
                </div>
                <div class="formula-text">
                    建议使用时间
                </div>
            </div>
            <div class="food-recommendation">
                1.食养果菜汁
            </div>
            <div class="recommendation-text" style="padding-top: 10px;">
                每天晚上20:00-20:30（2个汤方隔天交替使用）
            </div>
            <div class="recommendation-text">
                每次建议使用500毫升
            </div>
            <div class="food-recommendation">
                2.食养饮料
            </div>
            <div class="recommendation-text" style="padding-top: 10px;">
                每天上午10:00；下午16:00（2个汤方隔天交替使用）
            </div>
            <div class="recommendation-text">
                每次建议使用500毫升
            </div>
        </div>
        <div class="formulas">
            <div class="formulas-img">
                <img class="imgs"
                    src="https://image.giantgocloud.com/www//ImageMapping/image/20230719/82858D7703E74C21B6D93BD48D85B503.png"
                    alt="">
            </div>
            <div class="formula-texts">
                食养汤方做法
            </div>
        </div>
        <template v-for="(item, index) in foodProductList">
            <div class="practice funMedicine">
                <!-- <template v-if="index == 0">
                    <div style="margin-top: 10px;"></div>
                </template>
                <template v-if="index == 1">
                    <div style="margin-top: 10px;"></div>
                </template>
                <template v-if="index == 2">
                    <div style="margin-top: 10px;"></div>
                </template>
                <template v-if="index == 3">
                    <div style="margin-top: 10px;"></div>
                </template> -->
                <div class="practice-img" :style="{ backgroundImage: 'url(' + item.FoodPicture + ')' }">
                    <div class="practice-btn">{{ item.FoodName }}</div>
                </div>
                <div class="practice-list">
                    <div class="practiceTitle">
                        <div class="foodIcon">
                            <img class="img"
                                src="https://image.giantgocloud.com/www//ImageMapping/image/20230719/4B5967A5672A456288FDCC5A6CEB7B72.png"
                                alt="">
                        </div>
                        <div class="foodText">
                            材料
                        </div>
                    </div>
                    <div class="material">{{ item.FoodMaterial }}</div>
                </div>
                <div class="practice-list">
                    <div class="practiceTitle">
                        <div class="foodIcon">
                            <img class="img"
                                src="https://image.giantgocloud.com/www//ImageMapping/image/20230719/21FE74A18C144B67B221C952B103D7DA.png"
                                alt="">
                        </div>
                        <div class="foodText">
                            做法
                        </div>
                    </div>
                    <div class="material" v-html="item.FoodMethod"></div>
                </div>
                <div class="practice-list">
                    <div class="practiceTitle">
                        <div class="foodIcon">
                            <img class="img"
                                src="https://image.giantgocloud.com/www//ImageMapping/image/20230719/21FE74A18C144B67B221C952B103D7DA.png"
                                alt="">
                        </div>
                        <div class="foodText">
                            用法
                        </div>
                    </div>
                    <div class="material" v-html="item.FoodUseMethod"></div>
                </div>
                <div class="practice-list">
                    <div class="practiceTitle">
                        <div class="foodIcon">
                            <img class="img"
                                src="https://image.giantgocloud.com/www//ImageMapping/image/20230719/21FE74A18C144B67B221C952B103D7DA.png"
                                alt="">
                        </div>
                        <div class="foodText">
                            注意事项
                        </div>
                    </div>
                    <div class="material">
                        {{ item.FoodAttention }}
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { GetFoodWayListById } from '@/api/index'

export default {
    components: {

    },
    data() {
        return {
            name: '',
            openTime: '',
            foodProductList: ''
        }
    },
    props: {

    },
    created() {
        const id = this.$query('id')
        this.getCustomerFoodWayResult(id)
    },
    mounted() {
    },
    methods: {
        getCustomerFoodWayResult(id) {
            GetFoodWayListById({ id }).then(res => {
                const { data } = res
                if (data) {
                    this.name = data.Name
                    this.openTime = data.CreateTime
                    let foodList = [...data.drinksData, ...data.fruitData,]
                    this.foodProductList = foodList
                }
            })
        }
    }
}
</script>
<style lang='scss' scoped>
.recipes {
    width: 592.28px;
    min-height: 100vh;
    background-color: #2A2B3B;

    .funMedicine {
        height: 860px;
    }

    .basicInfo {
        .heads {
            display: flex;
            justify-content: center;

            .basicInfo-img {
                width: 375px;
                height: 163px;
                background-image: url('https://image.giantgocloud.com/Food/%E9%A3%9F%E7%96%97%E5%BC%80%E5%8F%91.png');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                padding-left: 36px;
                padding-top: 91px;

                .name {
                    font-size: 16px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    color: #2E9E66;
                }

                .Opening {
                    padding-top: 10px;
                    font-size: 10px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2E9E66;
                }
            }
        }

        .formula {
            display: flex;
            justify-content: center;
            margin: 5px 0;

            .formula-img {
                width: 30px;
                height: 30px;

                .img {
                    vertical-align: middle;
                    width: 100%;
                    height: 100%;
                }
            }

            .formula-text {
                font-size: 24px;
                font-family: FZCuSong-B09S-Regular, FZCuSong-B09S;
                color: #5EC692;
                padding-left: 5px;
            }
        }

        .foodProductList {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            .foodProductList-item {
                position: relative;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                margin: 16px 50px;
                width: 163px;
                height: 163px;
                border-radius: 10px;

                .round {
                    position: absolute;
                    left: 45%;
                    top: -13px;
                    background-image: url('https://image.giantgocloud.com/Food/ico.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 26px;
                    height: 26px;
                    font-size: 11px;
                    color: #fff;
                    text-align: center;
                    line-height: 26px;
                }

                .btn-text {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 38px;
                    background-color: #5EC692;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    text-align: center;
                    line-height: 38px;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }

        .food-recommendation {
            width: 153px;
            height: 36px;
            margin-left: 16px;
            margin-top: 20px;
            background: linear-gradient(91deg, #38394D 0%, #2B2C3C 100%);
            // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            // -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            // -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            border-radius: 24px;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            line-height: 36px;
            color: #fff;
        }

        .recommendation-text {
            padding-left: 35px;
            font-size: 14px;
            color: #fff;
        }

    }

    .formulas {
        display: flex;
        justify-content: center;
        margin-top: 90px;

        .formulas-img {
            width: 30px;
            height: 30px;

            .imgs {
                vertical-align: middle;
                width: 100%;
                height: 100%;
            }
        }

        .formula-texts {
            font-size: 24px;
            font-family: FZCuSong-B09S-Regular, FZCuSong-B09S;
            color: #5EC692;
            padding-left: 5px;
        }
    }

    .practice {
        margin-top: 10px;

        .practice-img {
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            width: 343px;
            height: 343px;
            margin: 0 auto;
            background-color: #fff;
            border-radius: 20px;

            .practice-btn {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 46px;
                background-color: #656464;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                color: #fff;
                text-align: center;
                line-height: 46px;
                font-size: 24px;
                font-weight: 600;
            }
        }

        .practice-list {
            margin: 0 40px;
            border-bottom: 2px solid #515151;
            padding: 28px 0;

            .practiceTitle {
                display: flex;

                .foodIcon {
                    width: 25px;
                    height: 25px;

                    .img {
                        width: 100%;
                        height: 100%;
                    }

                }

                .foodText {
                    font-size: 20px;
                    font-weight: 600;
                    color: #fff;
                }

            }

            .material {
                padding-top: 10px;
                font-size: 14px;
                color: #fff;
                font-weight: 400;
            }

        }
    }
}
</style>
